import { HTMLAttributes } from 'react';
import cn from 'classnames';
import { HubModuleCTA, PropsWithTestId } from '../../../../../../../types';
import { getCallToActionAttrs } from '../../../../helpers';
import { useHubContext } from '../../../../context/hubContext';
import { useGetImgixImageUrl } from '../../../../../../../hooks';
import { Anchor } from '../../../../../../shared/Anchor';
import styles from './Image.module.scss';

export interface ImageProps extends HTMLAttributes<HTMLElement> {
  path: string;
  link: HubModuleCTA;
  displayLink: boolean;
}

export function Image({
  path,
  link,
  displayLink,
  className,
  testId,
}: PropsWithTestId<ImageProps>) {
  const { indexPath } = useHubContext();
  const image = useGetImgixImageUrl(path);

  const imageContent = (
    <div
      style={{ backgroundImage: `url(${image})` }}
      className={cn(styles.image, className)}
      data-testid={testId}
    />
  );

  if (!link) return imageContent;

  const { url, label, ...attrs } = getCallToActionAttrs(link);

  return link?.shown && displayLink ? (
    <Anchor to={url} pathPrefix={indexPath} {...attrs}>
      {imageContent}
    </Anchor>
  ) : (
    imageContent
  );
}
