import cn from 'classnames';
import './Ripple.module.scss';

export interface RippleProps {
  count: 14 | 11 | 7;
  delay: 0 | 2 | 4 | 6;
}

export function Ripple({ count, delay }: RippleProps) {
  return (
    <div
      className={cn(`ripple-${count}-${delay}s`)}
      data-testid="ripple-container"
    >
      {new Array(count).fill(0).map((item, index) => (
        <div
          key={index}
          className={cn('ripple-frame', `ripple-frame-${index + 1}`)}
          data-testid="ripple-frame"
        />
      ))}
    </div>
  );
}
