import React from 'react';
import { ColumnTextSubsectionType } from '../index';
import styles from './OneColumnImageRightColumnStyle.module.scss';
import { getCallToActionAttrs } from '../../../../helpers';
import { Heading, Paragraph } from '../../../components';
import { CallToAction } from '../../../../CallToActionGroup';
import { ImageTag } from '../../../../../../shared/ImageTag/ImageTag';
import { Anchor } from '../../../../../../shared/Anchor';
import { useHubContext } from '../../../../context/hubContext';

export interface OneColumnImageRightColumnStyleProps {
  subsection: ColumnTextSubsectionType;
}

export default function OneColumnImageRightColumnStyle({
  subsection,
}: OneColumnImageRightColumnStyleProps) {
  const { indexPath } = useHubContext();
  const { image, title, body, link, displayCtaLinkOnImage, displayCtaButton } =
    subsection;

  const imageContent = image && (
    <ImageTag
      className={styles.imageHolder}
      src={image.path}
      testId="informa-image-tag"
    />
  );

  function getLinkContent() {
    const { url, label, ...attrs } = getCallToActionAttrs(link);

    return (
      <Anchor to={url} pathPrefix={indexPath} {...attrs}>
        {imageContent}
      </Anchor>
    );
  }

  return (
    <div className="col-xs-12">
      <div className={styles.offsetBottom}>
        <div className={styles.content}>
          <div className={styles.body}>
            <div className={styles.textWrapper}>
              {title && <Heading className={styles.heading}>{title}</Heading>}
              {body && <Paragraph className={styles.paragraph} text={body} />}
            </div>
            {image && link?.shown && displayCtaLinkOnImage
              ? getLinkContent()
              : imageContent}
          </div>
          {link?.shown && displayCtaButton && (
            <div className={styles.footer}>
              <CallToAction
                type="link"
                link={link}
                className={styles.itemLink}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
